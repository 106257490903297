import React from "react"

export default function About() {
  return (
    <section id="about">
      <div className="about-container">
        <div className="about-text-container">
          <h1>
            Hi, I'm Zach.
            <br/> 
            <br/>
            I love developing interesting web apps. 
          </h1>
          <p>
            Attended University of Maryland and graduated in 2013. Worked in construction as a project
            manager on a multitude of industrial construction projects from 2013-2021 and pivoted to pursue my growing interest in web development.
            Graduated from Flatiron School's Full-Stack Software Engineering bootcamp in November 2021.
            Here to make the world a little more accessible for everyone and build some innovative projects
            while I'm at it. 
          </p>
          <div className="about-button-container">
            <a
              href="#contact"
              className="work-button"
            >
              Work With Me
            </a>
            <a
              href="#projects"
              className="past-work-button"
            >
              See My Past Work
            </a>
          </div>
        </div>
        <div className="about-image-container">
          <img
            className="about-image"
            alt="hero"
            src="./coding.svg"
          />
        </div>
      </div>
    </section>
  )
}
