import { CodeIcon } from "@heroicons/react/solid"
import React from "react"
import { projects } from "../data"

export default function Projects() {
  return (
    <section id="projects">
      <div className="projects-container">
        <div className="projects-header-container">
          <CodeIcon className="code-icon" />
          <h1 className="projects-header-title">
            Apps I've Built
          </h1>
          <p className="projects-header-text">
            Here are some of my favorite apps that I've made:
          </p>
        </div>
        <div className="projects-images-container">
          {projects.map((project) => (
            <div className="projects-image-box">
                <a
                key={project.image}
                className="projects-images-link"
                >
                <div className="projects-info-container">
                    <img
                    alt="gallery"
                    className="projects-image"
                    src={project.image}
                    />
                    <div className="projects-title-container">
                    <h2 className="projects-subtitle">
                        {project.subtitle}
                    </h2>
                    <h1 className="projects-title">
                        {project.title}
                    </h1>
                    <p className="projects-description">{project.description}</p>
                    </div>
                </div>
                </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
