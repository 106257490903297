import React from "react";
import { useState } from "react";
import { send } from "emailjs-com"

export default function Contact() {

    const [toSend, setToSend] = useState({
        name: '',
        email: '',
        message: '',
    })

    function handleSubmit(e) {
        e.preventDefault();
        send(
            'service_9vxgk48',
            'template_qgx7ut9',
            toSend,
            'ECYXF7fhXqX9gr-80'
        )
        .then((response) => {
            alert("Message sent!");
            // setToSend({
            //     name: '',
            //     email: '',
            //     message: '',
            // })
        })
        .catch((err) => {
            alert(err);
        });
    };

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

  return (
    <section id="contact" className="relative">
        <div className="contact-container">
            <div className="contact-map-container">
                <iframe
                    width="100%"
                    height="100%"
                    title="map"
                    className="absolute inset-0"
                    frameBorder={0}
                    marginHeight={0}
                    marginWidth={0}
                    style={{ filter: "opacity(0.7)" }}
                    src="https://www.google.com/maps/embed/v1/place?q=saratoga+springs&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                />
                <div className="contact-address-container">
                    <div className="contact-address-div">
                        <h2 className="contact-title">
                            ADDRESS
                        </h2>
                        <p className="contact-address">
                            Saratoga Springs, NY
                        </p>
                    </div>
                    <div className="contact-email-container">
                        <h2 className="contact-title">
                            EMAIL
                        </h2>
                        <p className="contact-email">
                            zdurr22@gmail.com
                        </p>
                        <h2 className="contact-phone-title">
                            PHONE
                        </h2>
                        <p className="contact-phone">917-572-7646</p>
                    </div>
                </div>
            </div>
            <form
            netlify
            name="contact"
            onSubmit={handleSubmit}
            className="contact-form-container">
            <h2 className="contact-hire-title">
                Get in touch with me
            </h2>
            <p className="contact-hire-text">
            Do you need a good teammate with leadership experience for an upcoming project?
            Are you looking to hire a passionate full-stack developer for expansion of your current website?  
            Pease don`t hesitate, contact me with any questions or opportunities, let`s get to work!
            </p>
            <div className="contact-form-section-container">
                <label htmlFor="name" className="contact-form-label">
                Name
                </label>
                <input
                type="text"
                id="name"
                name="name"
                className="contact-form-input"
                onChange={handleChange}
                />
            </div>
            <div className="contact-form-section-container">
                <label htmlFor="email" className="contact-form-label">
                Email
                </label>
                <input
                type="email"
                id="email"
                name="email"
                className="contact-form-input"
                onChange={handleChange}
                />
            </div>
            <div className="contact-form-section-container">
                <label
                htmlFor="message"
                className="contact-form-label">
                Message
                </label>
                <textarea
                id="message"
                name="message"
                className="contact-form-input"
                onChange={handleChange}
                />
            </div>
            <button
                type="submit"
                className="contact-form-submit">
                Submit
            </button>
            </form>
        </div>
    </section>
  );
}