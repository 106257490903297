export const projects = [
  {
    title: "Crypto Dashboard",
    subtitle: "Crypto Currency Converter and Crypto News",
    description:
      "Crypto Currency dashboard featuring a converter that converts real time cost of multiple cryptocurrencies. Additionally featuring a news feed that displays the most recent headlines in the crypto world.",
    image: "./CryptoGif.gif",
    link: "https://google.com/",
  },
  {
    title: "Candy Crush",
    subtitle: "React Candy Crush",
    description:
      "React version of the popular iOS app, Candy Crush. Using state allow a user to make matches of three or four and award points accordingly.",
    image: "./CandyCrushGif.gif",
    link: "https://google.com",
  },
  {
    title: "Word Game",
    subtitle: "Word Association Game",
    description:
      "A game where synonymous words are fetched from an API and displayed. A player selects a word from two options that is most synonymous with the other words given.",
    image: "./WordGameGif.gif",
    link: "https://google.com",
  },
  {
    title: "MLB Stats App",
    subtitle: "Ruby Web Scraping App",
    description:
      "Ruby CLI app that grabs data from baseball-reference and allows you to choose an active player from any team and display that player's stats.",
    image: "./MLBStatsGif.gif",
    link: "https://google.com",
  },
];

export const testimonials = [
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
    image: "https://randomuser.me/api/portraits/men/1.jpg",
    name: "Jesse Hicks",
    company: "Zoozle",
  },
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
    image: "https://randomuser.me/api/portraits/men/94.jpg",
    name: "Ruben Alvarez",
    company: "Dooble.io",
  },
];

export const skills = [
  "JavaScript",
  "React",
  "Ruby on Rails",
  "Python",
  "SQL",
  "HTML/CSS",
];
