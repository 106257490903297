import { BadgeCheckIcon, ChipIcon } from "@heroicons/react/solid";
import React from "react";
import { skills } from "../data";

export default function Skills() {
  return (
    <section id="skills">
        <div className="skills-container">
            <div className="skills-header-container">
                <ChipIcon className="skills-chip-icon" />
                <h1 className="skills-title">
                    Skills &amp; Technologies
                </h1>
                <p className="skills-title-p">
                    These are some of the languages and technologies that I have
                    learned and been utilizing during my time working as a developer.
                </p>
            </div>
            <br/>
            <div className="skills-information-container">
                {skills.map((skill) => (
                    <div key={skill} className="skill-container">
                        <div className="skill-info">
                            <BadgeCheckIcon className="check-badge" />
                            <span className="skill">
                            {skill}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>
  );
}