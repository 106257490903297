import { ArrowRightIcon } from "@heroicons/react/solid";
import React from "react";

export default function Navbar() {
  return (
    <header className="navbar-header">
        <div className="navbar-container">
            <nav className="navbar-about-wrapper">
                <a href="#about" className="navbar-about-link">
                    Zachary Durr
                </a>
            </nav>
            <nav className="navbar-links-wrapper">
                <a href="#projects" className="navbar-link">
                    Past Work
                </a>
                <a href="#skills" className="navbar-link">
                    Skills
                </a>
                {/* <a href="#testimonials" className="navbar-link">
                    Testimonials
                </a> */}
            </nav>
            <a
            href="#contact"
            className="navbar-contact-link">
            Work With Me
            <ArrowRightIcon className="navbar-arrow" />
            </a>
        </div>
    </header>
  );
}